export default defineNuxtRouteMiddleware(() => {
    const authEndpoints = useAuthEndpoints();
    const { session } = authEndpoints.useAuth();
    const tenants = session.value?.tenants;

    if (!tenants || tenants?.length === 0) {
        return navigateTo('/login');
    }

    return true;
});
